import React, { useState, useEffect } from 'react';
import './PasswordRecovery.css';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { httpGet } from '../../http';
import { Alert, Spinner } from 'react-bootstrap';
const TextBox = (props) => {
    return(
        <input className='password-recovery-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} />
    );
}

const PasswordRecovery = ({ history }) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        loading: true,
        error: {}
    });

    const [ recovery, setRecovery ] = useState({
        nit: '',
        loading: false,
        error: false,
        errorMsg: ''
    });

    useEffect(() => {
        setEstado(
            {
                ...estado,
                empresa: JSON.parse(sessionStorage.getItem('estado'))
            });
    }, []);

    const handleChange = (e) => {
        setRecovery({
            ...recovery,
            [e.target.name]: e.target.value
        });
    }

    const recoveryPassword = () => {
        if(recovery.nit.length > 1){
            setRecovery({
                ...recovery,
                loading: true
            });
            httpGet('/api/v1/proveedor/recovery/' + recovery.nit).then(
                resp => {
                    setRecovery({
                        ...recovery,
                        loading: false
                    });
                    history.push({ pathname: '/login', state: { recovery: true } });
                }
            ).catch(
                error => {
                    console.log(error);
                    if(error.response.status){
                        if(error.response.status === 404 ){
                            setRecovery({
                                ...recovery,
                                error: true,
                                errorMsg: 'El NIT ingresado no esta registrado.'
                            })
                        }

                        if(error.response.status === 500 ){
                            setRecovery({
                                ...recovery,
                                error: true,
                                errorMsg: 'Ocurrio un error al recuperar la contraseña.'
                            })
                        }
                    }
                }
            );
        }
    }

    return(
        <div className='passwordRecovery-container'>
            <div className='passwordRecovery-content'>
                <div className='passwordRecovery-body'>
                    { recovery.error &&
                        <Alert variant='danger'>{ recovery.errorMsg }</Alert>
                    }
                    <div>
                        <h2 className='password-recovery-h2'>Recuperación de contraseña</h2>
                    </div>
                    <div>
                        <p className='password-recovery-p'>Se creara una nueva contraseña la cual sera enviada a su correo electronico, si no tiene acceso a su correo electronico comuniquese con { estado.empresa.nombreAlterno }</p>
                    </div>
                    { recovery.loading ?
                        <div className='loading-spinner'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    :
                        <div className='passwordRecovery-form'>
                            <div className='passwordRecovery-field'>
                                <div className='passwordRecovery-field-name'>Por favor ingrese su NIT:</div>
                                <TextBox type='text' name='nit' value={recovery.nit} onChange={handleChange}  />
                            </div>
                            <Button onClick={recoveryPassword}>Generar nueva contraseña</Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default PasswordRecovery;