import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import Home from './Home';
import Egresos from '../egresos/Egresos';
import EgresoDetalle from '../egresos/EgresoDetalle';
import DocumentosDetalle from '../egresos/DocumentosDetalle';
import DocumentoDetalle from '../egresos/DocumentoDetalle';
import Notas from '../notas/Notas';
import NotaDetalle from '../notas/NotaDetalle';
import Retencion from '../certificados/Retencion';
import ReteICA from '../certificados/ReteICA';
import ReteIVA from '../certificados/ReteIVA';
import ReteIVADetalle from '../certificados/ReteIVADetalle';
import EgresoPDF from '../egresos/EgresoPDF';
import NotaPDF from '../egresos/NotaPDF';
import CentrosOperacion from '../notas/CentrosOperacion';
import Certificados from '../certificados/Certificados';
import CertificadoPDF from '../certificados/CertificadoPDF';
import Profile from '../../pages/profile/Profile';
import CertificadoCiudades from '../certificados/CertificadoCiudades';
import DocumentosCruzados from '../documentosCruzados/DocuementosCruzados';
import DocumentoCruzadoDetalle from '../documentosCruzados/DocumentoCruzadoDetalle';
import DocumentoCruzadoPDF from '../documentosCruzados/DocumentoCruzadoPDF';
import OtrosDocumentos from '../OtrosDocumentos/OtrosDocumentos';
import OtrosDocumentosDetalle from '../OtrosDocumentos/OtrosDocumentosDetalle';
import DocumentoPDF from '../OtrosDocumentos/DocumentoPDF';
import Facturas from '../facturas/Facturas';
import DocumentoFiPDF from '../egresos/DocumentoFiPDF';
import DocumentoFinancieroDetalle from '../egresos/DocumentoFinancieroDetalle';
import NotasContables from '../notas/NotasContables';
import NotaContableDetalle from '../notas/NotaContableDetalle';
import NotaContablePDF from '../notas/NotaContablePDF';
import RetencionDetalle from '../certificados/RetencionDetalle';
import { Link } from 'react-router-dom';
import { httpGet } from '../../http';
import './HomeLayout.css';
import logo from '../../img/logo.png';

const MenuOption = (props) =>  {
    return(
        <div className='menu-option-wrapper'>
            <div className='menu-icon-container' onMouseOver={props.onMouseOver} onMouseLeave={ props.onMouseLeave }>
                <Link style={{ color: 'white' }} to={props.to}><FontAwesomeIcon className='menu-icon' icon={props.icon} /></Link>
            </div>
            <div className={props.className}>{props.children}</div>
        </div>
    );
}

const HomeLayout = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect( async () => {

        if(localStorage.getItem('nitProveedor')){
            await explore();
        } else {
            if(!sessionStorage.getItem('estado')){
                props.history.push('/');
                return 0;
            }
    
            if(!sessionStorage.getItem('proveedor')){
                props.history.push('/login');
                return 0;
            }
    
            console.log(JSON.parse(sessionStorage.getItem('estado')));
    
            let newEstado = JSON.parse(sessionStorage.getItem('estado'));
            newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
            console.log(newEstado);
            
            setEstado(newEstado);
        }


    }, []);

    const [ menu, setMenu ] = useState({
        menuClass: 'menu',
        menuOptionText: 'menu-option-text'
    });

    const explore = async () => {
        try{

            const empresa = await httpGet('/api/v1/empresa/nit/805029321');

            console.log("EMPRESA:");
            console.log(empresa);
            if(empresa.data){
                const proveedor = await httpGet('/api/v1/proveedor/nit/' + localStorage.getItem("nitProveedor"));
                sessionStorage.setItem('proveedor', JSON.stringify(proveedor.data));
                localStorage.removeItem("nitProveedor")
                let newEstado = JSON.parse(sessionStorage.getItem('estado'));
                newEstado.proveedor = proveedor.data !== null ? proveedor.data : estado.proveedor;
                console.log(newEstado);
            
                setEstado(newEstado);
            }

            
            
        } catch(error){

        }
    }

    const handleMouseOver = () => {
        setMenu({
            ...menu,
            menuClass: 'menu-active',
            menuOptionText: 'menu-option-text-active'
        });
    }

    const handleMouseLeave = () => {
        setMenu({
            ...menu,
            menuClass: 'menu',
            menuOptionText: 'menu-option-text'
        });
    }

    const exit = () => {
        sessionStorage.removeItem('proveedor');
        props.history.push('/');
    }

    return(
        <div>
            <Navbar fixed="top" bg="dark" variant="dark" collapseOnSelect expand="md" style={{ height: '40px', justifyContent: 'space-between' }}> 
                <Navbar.Brand className="brand-title" href="#home">Portal para proveedores - { estado.empresa.razonSocial }</Navbar.Brand>
                <Navbar.Text>Hola { estado.proveedor.razonSocial } <a href="#" onClick={exit}>Salir</a></Navbar.Text>
            </Navbar>
        
        
            <div className='home-wrapper'>
                <div className={menu.menuClass} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <div className='menu-logo-wrapper'>
                    </div>
                    <MenuOption className={menu.menuOptionText} icon={faFileInvoice} to='/portal/facturas'>Estado de facturas</MenuOption>
                    <MenuOption className={menu.menuOptionText} icon={faFileInvoiceDollar} to='/portal/egresos'>Comprobantes de egreso</MenuOption>
                    {/* <MenuOption className={menu.menuOptionText} icon={faFileInvoiceDollar} to='/portal/otros-documentos'>Otros documentos</MenuOption> */}
                    <MenuOption className={menu.menuOptionText} icon={faFileAlt} to='/portal/co-notas'>Notas</MenuOption>
                    <MenuOption className={menu.menuOptionText} icon={faFileAlt} to='/portal/notas-contables'>Notas Contables</MenuOption>
                    {/*<MenuOption className={menu.menuOptionText} icon={faFileInvoiceDollar} to='/portal/documentos-cruzados'>Documentos cruzados</MenuOption>*/}
                    <MenuOption className={menu.menuOptionText} icon={faAward} to='/portal/certificados'>Certificados</MenuOption>
                    <MenuOption className={menu.menuOptionText} icon={faUserCircle} to='/portal/profile'>Perfil</MenuOption>
                </div>
            </div>

            <div className='home-logo-container'>
                <img className='home-logo' src={ logo } />
            </div>
            
            <div className='home-main'>
                <Switch>
                    <Route path="/portal/egresos" render={() => (<Egresos history={props.history} />)} />
                    <Route path="/portal/egreso-detalle" render={() => (<EgresoDetalle history={props.history} />)} />
                    <Route path="/portal/documentos-detalle" render={() => (<DocumentosDetalle history={props.history} />)} />
                    <Route path="/portal/documento-detalle" render={() => (<DocumentoDetalle history={props.history} />)} />
                    <Route path="/portal/notas" render={() => (<Notas history={props.history} />)} />
                    <Route path="/portal/nota-detalle" render={() => (<NotaDetalle history={props.history} />)} />
                    <Route path="/portal/notas-contables" render={() => (<NotasContables history={props.history} />)} />
                    <Route path="/portal/nota-contable-detalle" render={() => (<NotaContableDetalle history={props.history} />)} />
                    <Route path="/portal/nota-contable-pdf" render={() => (<NotaContablePDF history={props.history} />)} />
                    <Route path="/portal/retencion" render={() => (<Retencion history={props.history} />)} />
                    <Route path="/portal/retencion-detalle" render={() => (<RetencionDetalle history={props.history} />)} />
                    <Route path="/portal/rete-ica" render={() => (<ReteICA history={props.history} />)} />
                    <Route path="/portal/rete-iva" render={() => (<ReteIVA history={props.history} />)} />
                    <Route path="/portal/rete-iva-detalle" render={() => (<ReteIVADetalle history={props.history} />)} />
                    <Route path="/portal/egreso-pdf" render={() => (<EgresoPDF history={props.history} />)} />
                    <Route path="/portal/nota-pdf" render={() => (<NotaPDF history={props.history} />)} />
                    <Route path="/portal/co-notas" render={() => (<CentrosOperacion history={props.history} />)} />
                    <Route path="/portal/certificados" render={() => (<Certificados history={props.history} />)} />
                    <Route path="/portal/certificado-pdf" render={() => (<CertificadoPDF history={props.history} />)} />
                    <Route path="/portal/Profile" render={() => (<Profile history={props.history} />)} />
                    <Route path="/portal/certificado-ciudades" render={() => (<CertificadoCiudades history={props.history} />)} />
                    {/*<Route path="/portal/documentos-cruzados" render={() => (<DocumentosCruzados history={props.history} />)} />
                    <Route path="/portal/documento-cruzado-detalle" render={() => (<DocumentoCruzadoDetalle history={props.history} />)} />
                    <Route path="/portal/documento-cruzado-pdf" render={() => (<DocumentoCruzadoPDF history={props.history} />)} />*/}
                    <Route path="/portal/otros-documentos" render={() => (<OtrosDocumentos history={props.history} />)} />
                    <Route path="/portal/otros-documentos-detalle" render={() => (<OtrosDocumentosDetalle history={props.history} />)} />
                    <Route path="/portal/documento-pdf" render={() => (<DocumentoPDF history={props.history} />)} />
                    <Route path="/portal/facturas" render={() => (<Facturas history={props.history} />)} />
                    <Route path="/portal/documento-financiero-detalle" render={() => (<DocumentoFinancieroDetalle history={props.history} />)} />
                    <Route path="/portal/documento-financiero-pdf" render={() => (<DocumentoFiPDF history={props.history} />)} />
                    <Route path="/portal" render={() => (<Home estado={estado} history={props.history} />)} />
                </Switch>
            </div>

        </div>
    );
}

export default HomeLayout
