import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { Row, Col } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { httpGet } from '../../http';
import './Certificados.css';

const Certificado = (props) => {
    return(
        <div style={{ marginBottom: '20px' }}>
            <div className='certificado-container'>
                <div className='certificado-icon-container'>
                    <FontAwesomeIcon className='view-icon' icon={faMapMarkedAlt} />
                </div>
                <div>
                    <div className='certificado-title'>{ props.children }</div>
                </div>
            </div>
        </div>
    );
}

const CertificadoCiudades = (props) => {

    const { tipo } = props;

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [ ciudades, setCiudades ] = useState([
        {
            id: 1,
            tipo: 1,
            nivel: 2,
            descripcion: 'Retención I.C.A.',
            cuenta: '236865',
            ciudad: false,
            ciudadRetencion: 'PALMIRA'
        },
        {
            id: 2,
            tipo: 1,
            nivel: 1,
            descripcion: 'Retención I.C.A.',
            cuenta: '236810',
            ciudad: true,
            ciudadRetencion: 'JAMUNDÍ'
        },
        {
            id: 3,
            tipo: 1,
            nivel: 1,
            descripcion: 'Retención I.C.A.',
            cuenta: '236845',
            ciudad: false,
            ciudadRetencion: 'RETIRO'
        },
        {
            id: 4,
            tipo: 1,
            nivel: 1,
            descripcion: 'Retención I.C.A.',
            cuenta: '236805',
            ciudad: false,
            ciudadRetencion: 'PUERTO TEJADA'
        },
        {
            id: 5,
            tipo: 1,
            nivel: 1,
            descripcion: 'Retención I.C.A.',
            cuenta: '236820',
            ciudad: false,
            ciudadRetencion: 'MARINILLA'
        },
        {
            id: 6,
            tipo: 1,
            nivel: 1,
            descripcion: 'Retención I.C.A.',
            cuenta: '236830',
            ciudad: false,
            ciudadRetencion: 'VILLARICA'
        },
        {
            id: 6,
            tipo: 1,
            nivel: 1,
            descripcion: 'Retención I.C.A.',
            cuenta: '236835',
            ciudad: false,
            ciudadRetencion: 'BUGA'
        },
        {
            id: 7,
            tipo: 1,
            nivel: 1,
            descripcion: 'Retención I.C.A.',
            cuenta: '236840',
            ciudad: false,
            ciudadRetencion: 'COPACABANA'
        }
    ]);

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        //getCiudades();

    }, []);

    const getCiudades = async () => {
        const resp = await httpGet('/api/v1/ciudad-reteica');
        if(resp){
            setCiudades(resp.data);
        }
    }

    return(
        <div className='page-container'>
            <Title>Certificados</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial } </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Seleccione la ciudad del certificado</SubTitle>
                <div>
                    <Row className='justify-content-md-center co-row'>
                        {
                            ciudades.map(
                                (ciudad, index) => {
                                    return(
                                        <Col key={index} className='co-col' xs={12} md={6} lg={6} xl={4} >
                                                <Link to={{ pathname: '/portal/retencion', state: { tipo: ciudad } }}><Certificado>{ciudad.descripcion} - {ciudad.ciudadRetencion}</Certificado></Link>
                                        </Col>
                                    );
                                }
                            )
                        }                        
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default CertificadoCiudades;