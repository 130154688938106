import React, { useState, useEffect} from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
//import './EgresoDetalle.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
 

const NotaContableDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [nota, setNota ] = useState(
        {
            encabezado: {
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                detalle: '',
                valor: '',
                debitos: '',
                creditos: '',
            },
            detalles: [],
            loading: false,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.documento){
            props.history.push('/portal');
            return 0;
        }
        
        getNotaDetalle(props.history.location.state.documento);

    }, []);

    const getNotaDetalle = async (nota2) => {
        setNota({
            ...nota,
            loading: true
        });

        const companyId = sessionStorage.getItem('companyId');
        
        const encabezado = await httpGet('/api/v1/notas/cg/encabezado/' + nota2.coMov + '/' + nota2.tipoDoc + '/' + nota2.numDoc);
        const detalles = await httpGet('/api/v1/egresos/documentos/' + nota2.coMov + '/' + nota2.tipoDoc + '/' + nota2.numDoc);
        console.log(encabezado);

        if((encabezado !== null) && (encabezado !== undefined)){
            setNota({
                ...nota,
                encabezado: encabezado.data[0],
                detalles: detalles.data,
                loading: false
            });
        }
        
    }
    

    const generarNotaDetallePdf = async () => {

        let newNota = {
            empresa: estado.empresa,
            proveedor: estado.proveedor,
            encabezado: nota.encabezado,
            detalles: nota.detalles
        }

        props.history.push({ pathname: '/portal/nota-contable-pdf', state: { nota: newNota } });
    }

    return(
        <div className='page-container'>
            <Title>Detalle nota contable</Title>
            <div className='page-body'>
                <SubTitle>Empresa: {estado.empresa.razonSocial}</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle de la nota</SubTitle>
                {nota.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>C. O.</th>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            {/*<th>FECHA DE VENCIMIENTO</th>*/}
                            <th>DETALLE</th>
                            <th>VALOR DEL DOCUMENTO</th>                                                        
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ nota.encabezado.idCo }</td>
                            <td>{ nota.encabezado.tipoDoc + '-' + nota.encabezado.numDoc }</td>
                            <td>{ formatDateMin( new Date(nota.encabezado.fechaDoc) ) }</td>
                            {/*<td>{ nota.encabezado.fechaVcto }</td>*/}
                            <td>{ nota.encabezado.notas }</td>
                            <td>${ formatNumber(nota.encabezado.debitos) }</td>
                            <td>${ formatNumber(nota.encabezado.debitos) }</td>
                            <td>${ formatNumber(nota.encabezado.creditos) }</td>
                            <td><div className='pdf-icon-container' onClick={generarNotaDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CENTRO DE OPERACION</th>
                            <th>CUENTA</th>
                            <th>DETALLE</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            nota.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={detalle.id}>
                                            <td>{(index + 1)}</td>
                                            <td>{ detalle.coDoc }</td>
                                            <td>{ detalle.idCuenta }</td>
                                            <td>{ detalle.detalle }</td>
                                            <td>${ formatNumber(detalle.debitos) }</td>
                                            <td>${ formatNumber(detalle.creditos) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                </>
                }
            </div>
        </div>
    );
}

export default NotaContableDetalle;
