import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import './DocumentoDetalle.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';

const DocumentoDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
       
        setEstado(newEstado);

        if(!props.history.location.state.documento){
            props.history.push('/portal');
            return 0;
        }
        
        getDocumentoDetalle(props.history.location.state.documento);

    }, []);

    const getDocumentoDetalle = async (doc) => {
        setDocumento({
            ...documento,
            loading: true
        });
                
        const encabezado = await httpGet('/api/v1/egresos/documento/' + doc.co + '/' + doc.tipoDoc + '/' + doc.numDoc);
        const detalles = await httpGet('/api/v1/egresos/documento-detalle/' + doc.co + '/' + doc.tipoDoc + '/' + doc.numDoc);
        //const co = await httpGet('/ProveedoresWS/api/CentroOperacion/codigo?emp=' + sessionStorage.getItem('companyId') + '&cod=' + doc.co);

        console.log(encabezado);
        console.log(detalles);

        if((encabezado.data !== null) && (encabezado.data !== undefined)){
            setDocumento({
                ...documento,
                encabezado: encabezado.data[0],
                detalles: detalles.data,
                loading: false
            });
        }
        
    }

    const [documento, setDocumento ] = useState(
        {
            encabezado: {
                co: '',
                coDescripcion: '',
                tipoDoc: '',
                numDoc: '',
                fechaDoc: new Date(),
                detalle: '',
                terceroDescripcion: '',
                numDocProv: '',
                totalBruto: '',
                descuentos: '',
                impuestos: '',
                totalNeto:''
            },
            detalles: [],
            loading: false,
            error: {},
            reg: 0
        }
    );

    return(
        <>
        <div className='page-container'>
            <Title>Documento de proveedor - Detalle</Title>
            <div className='page-body'>
                <SubTitle>Empresa: {estado.empresa.razonSocial}</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                    <div><span className='info-title'>Centro de operación </span><span>{ documento.encabezado.co } - { documento.encabezado.coDescripcion }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle del documento</SubTitle>
                { documento.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                    <>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                <th>DETALLE</th>
                                <th>PROVEEDOR</th>            
                                <th>DOC. CRUCE</th>
                                <th>VALOR BRUTO</th>
                                <th>DESCUENTOS</th>
                                <th>IMPUESTOS</th>
                                <th>TOTAL</th>
                                <th>DESCARGAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ documento.encabezado.co + " - " + documento.encabezado.tipoDoc + '-' + documento.encabezado.numDoc }</td>
                                <td>{ formatDateMin( new Date(documento.encabezado.fechaDoc)) }</td>
                                <td>{ documento.encabezado.detalle }</td>
                                <td>{ documento.encabezado.terceroDescripcion }</td>
                                <td>{ documento.encabezado.numDocProv }</td>
                                <td>${ formatNumber(documento.encabezado.totalBruto) }</td>
                                <td>${ formatNumber(documento.encabezado.descuentos) }</td>
                                <td>${ formatNumber(documento.encabezado.impuestos) }</td>
                                <td>${ formatNumber(documento.encabezado.totalNeto) }</td>
                                <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} to={{ pathname: '/portal/nota-pdf', state: { documento: { empresa: estado.empresa, proveedor: estado.proveedor, encabezado: documento.encabezado, detalles: documento.detalles } } } } ><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>ITEM</th>
                                <th>DESCRIPCION</th>
                                <th>CANTIDAD</th>
                                <th>UNIDAD DE MEDIDA</th>
                                <th>VALOR BRUTO</th>
                                <th>DESCUENTOS</th>
                                <th>IMPUESTOS</th>
                                <th>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                documento.detalles.map(
                                    detalle => {
                                        return(
                                            <tr key={detalle.id}>
                                                <td>{ detalle.idItem }</td>
                                                <td>{ detalle.descripcionItem }</td>
                                                <td>{ detalle.cantidad }</td>
                                                <td>{ detalle.unidadMedida }</td>
                                                <td>${ formatNumber(detalle.totalBruto) }</td>
                                                <td>${ formatNumber(detalle.descuentos) }</td>
                                                <td>${ formatNumber(detalle.impuestos) }</td>
                                                <td>${ formatNumber(detalle.totalNeto) }</td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                    </>
                }
            </div>
        </div>
        </>
    );
}

export default DocumentoDetalle
