import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
//import './Egresos.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';


const NotasContables = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        getNotas(newEstado.proveedor.nit);

    }, []);

    const getNotas = (tercero) => {
        setNotas({
            ...notas,
            loading: true
        });
        const companyId = sessionStorage.getItem('companyId');
        httpGet('/api/v1/notas/cg/nit/' + tercero).then(
            resp => {
                setNotas({
                    ...notas,
                    data: resp.data,
                    loading: false
                });
            }
        ).catch(
            error => {
                console.log(error);
                setNotas({
                    ...notas,
                    loading: false
                });
            }
        );
    }

    const [notas, setNotas ] = useState(
        {
            data: [],
            loading: true,
            error: {}
        }
    );

    const generarNotaDetallePdf = async (e, nota2) => {
        e.preventDefault();

        console.log(nota2);
        const companyId = sessionStorage.getItem('companyId');
        const encabezado = await httpGet('/ProveedoresWS/api/Egresos/encabezado?emp=' + companyId + '&co=' + nota2.co + '&tipoDocumento=' + nota2.tipoDoc + '&numeroDocumento=' + nota2.numeroDoc);
        const detalles = await httpGet('/ProveedoresWS/api/Egresos/detalle?emp=' + companyId + '&co=' + nota2.co + '&tipoDocumento=' + nota2.tipoDoc + '&numeroDocumento=' + nota2.numeroDoc);

        if((encabezado !== null) && (encabezado !== undefined)){
            let notaNew = {
                empresa: estado.empresa,
                proveedor: estado.proveedor,
                encabezado: encabezado.data,
                detalles: detalles.data
            }


            props.history.push({ pathname: '/portal/nota-contable-pdf', state: { nota: notaNew } });
        }
        
    }

    return(
        <div className='page-container'>
            <Title>Notas Contables</Title>
            {notas.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
            :
                <div className='page-body'>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>CENTRO DE OPERACION</th>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                <th>VALOR DEL DOCUMENTO</th>
                                <th>DETALLE</th>
                                <th>VER</th>
                                {/*<th>DESCARGAR</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                notas.data.map(
                                    (nota, index) => {
                                        return(
                                            <tr key={nota.id}>
                                                <td>{ index + 1 }</td>
                                                <td>{ nota.coMov + '-' + nota.coDescripcion }</td>
                                                <td>{ nota.tipoDoc + '-' + nota.numDoc }</td>
                                                <td>{ formatDateMin( new Date(nota.fechaDoc) ) }</td>
                                                <td>${ formatNumber(nota.valor) }</td>
                                                <td>{ nota.detalle }</td>
                                                <td><div className='view-icon-container'><Link to={{ pathname: "/portal/nota-contable-detalle", state: { documento: nota } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                {/*<td><div className='pdf-icon-container' ><Link style={{ color: 'red' }} onClick={(e) => { generarNotaDetallePdf(e, nota) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>*/}
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default NotasContables
