import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Welcome.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import Loading from '../../components/loading/Loading';


const Welcome = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {
        
        setEstado({
            ...estado,
            loading: true
        })

        httpGet('/api/v1/empresa/nit/805029321').then(
            resp => {
                setEstado({
                    ...estado,
                    empresa: resp.data,
                    loading: false
                })

                sessionStorage.setItem('estado', JSON.stringify({
                    empresa: resp.data,
                    loading: true,
                    error: {}
                }));
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }, []);

    return(
        <div>
            { estado.loading &&
                <Loading />
            }
            { !estado.loading &&            
            <div className='welcome-content'>
                <div>
                    <h2 className='welcome-h2'>Bienvenido al portal de Proveedores</h2>
                </div>
                <br />
                <div>
                    <p className='welcome-p'>Este es el portal de { estado.empresa.razonSocial } para Proveedores</p>
                    <br />
                    <p className='welcome-p'>Aquí usted prodrá:</p>
                    <ul>
                        <li className='welcome-li'>Ver sus comprobantes de pago</li>
                        <li className='welcome-li'>Ver sus notas de proveedor</li>
                        <li className='welcome-li'>Obtener certificados</li>
                    </ul>
                </div>
                <div>
                    <p className='welcome-h2'>Seleccione que desea hacer:</p>
                    <ul>
                        <li className='welcome-li'><Link to={{ pathname: '/login' }}>Ya tiene una cuenta de proveedor? Ingrese aqui</Link></li>
                        <li className='welcome-li'><Link to={{ pathname: '/signup' }}>Registrese como proveedor</Link></li>
                    </ul>
                </div>
            </div>
            }
        </div>
    );
}

export default Welcome;